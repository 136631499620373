<template>
<!--  <div class="address-box">
    <div class="address-list">
      <van-address-list
          v-model="chosenAddressId"
          :list="addressList"
          default-tag-text="默认"
          @add="onAdd"
          @edit="onEdit"
          @select="selectedAddress"
      />
      <wechat/>
    </div>
  </div>-->
  <div class="custom-address-list">
    <van-empty v-if="!addressList || addressList.length<=0"
        class="custom-image"
        :image="require('@/assets/images/address/noAddress.png')"
        description="您还没有添加地址"
    />
    <van-address-list
        v-model="chosenAddressId"
        :list="addressList"
        default-tag-text="默认"
        add-button-text="新增收货地址"
        @add="onAdd"
        @edit="onEdit"
        @select="selectedAddress"
    />
    <wechat/>
  </div>
</template>

<script>
import Vue from 'vue';
import {AddressList, Toast, Image, Row, Col, Empty} from 'vant';
import {getMemberAddressList} from '@/api/address'
import Wechat  from '@/components/Wechat'
import {mapGetters} from 'vuex'
Vue.use(AddressList);
Vue.use(Toast);
Vue.use(Image);
Vue.use(Row);
Vue.use(Col);
Vue.use(Empty)



export default {
  components: {Wechat},
  data() {
    return {
      chosenAddressId: '',
      addressList: [],
    };
  },
  computed: {
    ...mapGetters({
      productId: 'getProductId',
      model: 'getModel',
      invitationCode: 'getInvitationCode',
      skuId: 'getSkuId',
      quantity: 'getQuantity',
      addressId: 'getAddressId',
      delivery: 'getDelivery',
    }),
  },
  methods: {
    // 选中地址触发
    selectedAddress(item, index) {
      console.log(item);
      console.log(index);
      this.$store.dispatch('updateAddressId', item.id)

      const query = {
        id: this.productId,
        model: this.model,
        skuId: this.skuId || undefined,
        invitationCode: this.invitationCode,
        quantity: this.quantity,
        addressId: this.addressId,
        delivery: this.delivery,
      };
      this.$router.replace({name:'orderConfirm', query: query})
    },
    // 新增
    onAdd() {
      this.$router.push({path: '/addressEdit'});
    },
    // 编辑
    onEdit(item) {
      this.$router.push({path: '/addressEdit', query: {addressId: item.id}});
    },
    // 地址列表
    getAddressList() {
      getMemberAddressList().then(res => {
        if (res.code === 200) {
          var addresses = res.data;
          for (var i = 0; i < addresses.length; i++) {
            var address = {};
            if (addresses[i].defaultStatus) {
              this.chosenAddressId = addresses[i].id;
              address.isDefault = true
            } else {
              address.isDefault = false
            }
            address.id = addresses[i].id;
            address.name = addresses[i].name;
            address.tel = addresses[i].phoneNumber;
            address.address = addresses[i].provinceName + addresses[i].cityName + addresses[i].regionName + addresses[i].detailAddress;
            this.addressList.push(address);
          }
        } else {
          Toast.fail(res.message || '地址列表获取失败，请稍候再试');
        }

      }, err => {
        Toast.fail(err.message || '地址保存失败，请稍候再试');
      });
    }
  }, created() {
    // 地址列表
    this.getAddressList();
  }
}
</script>

<style  lang="sass">
.custom-address-list
  .custom-image
    .van-empty__image
      width: 220px
      height: 140px
  .van-address-list
    .van-radio-group
      background-color: #fff
      border-radius: 5px
      .van-address-item
        border-bottom: #f4f5f9 solid 1px
</style>
